import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import PageHeader from '~/components/PageHeader';
import { Heading2, Heading3, Heading4, Paragraph } from '@entur/typography';
import { PurposeIcon, ViewIcon, ValueIcon } from '@entur/icons';
import { GridContainer, GridItem } from '@entur/grid';
import { Contrast } from '@entur/layout';
import './merkevareplattform.scss';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader title="Merkevareplattform" mdxType="PageHeader" />
    <GridContainer className="merkevare__grid-container" mdxType="GridContainer">
  <GridItem className="merkevare__blue-box" small={12} medium={6} mdxType="GridItem">
    <Heading3 as="div" style={{
          marginBottom: '12px'
        }} margin="bottom" mdxType="Heading3">
      Formål
    </Heading3>
    <div>
      Kobler kollektiv-Norge sammen slik at det blir enkelt å planlegge, kjøpe
      og reise kollektivt.
    </div>
  </GridItem>
  <GridItem className="merkevare__blue-box" small={12} medium={6} mdxType="GridItem">
    <Heading3 as="div" style={{
          marginBottom: '12px'
        }} margin="bottom" mdxType="Heading3">
      Visjon
    </Heading3>
    <div>I samarbeid om enkle, bærekraftige reiser.</div>
  </GridItem>
  <GridItem small={12} as={Contrast} className="merkevare__slogan" mdxType="GridItem">
    <Heading2 margin="none" as="span" mdxType="Heading2">
      Vi kommer lenger sammen
    </Heading2>
  </GridItem>
  <GridItem className="merkevare__blue-box" small={12} mdxType="GridItem">
    <Heading3 as="div" margin="none" mdxType="Heading3">
      Verdier
    </Heading3>
    <GridContainer mdxType="GridContainer">
      <GridItem small={12} medium={6} mdxType="GridItem">
        <div className="merkevare__lofte">
          <div className="merkevare__lofte--header">Tilstede</div>
          uten å være påtrengende.
        </div>
        <div className="merkevare__lofte">
          <div className="merkevare__lofte--header">Raus</div>
          men innenfor fornuftige rammer
        </div>
        <div className="merkevare__lofte">
          <div className="merkevare__lofte--header">Utforskende</div>
          men uten å miste virkelighetsforståelsen
        </div>
      </GridItem>
      <GridItem small={12} medium={6} mdxType="GridItem">
        <div className="merkevare__lofte">
          <div className="merkevare__lofte--header">Nyttig</div>
          men innenfor vårt samfunnsoppdrag
        </div>
        <div className="merkevare__lofte">
          <div className="merkevare__lofte--header">Med integritet</div>
          uten å være arrogante
        </div>
      </GridItem>
    </GridContainer>
  </GridItem>
  <GridItem className="merkevare__blue-box" small={12} mdxType="GridItem">
    <Heading3 as="span" margin="none" mdxType="Heading3">
      Løfter
    </Heading3>
    <GridContainer mdxType="GridContainer">
      <GridItem small={12} medium={6} mdxType="GridItem">
        <div className="merkevare__lofte">
          <div className="merkevare__lofte--header">For de reisende</div>
          skal vi gjøre det enklere å velge kollektivt.
        </div>
        <div className="merkevare__lofte">
          <div className="merkevare__lofte--header">For reisetilbydere</div>
          skal vi levere en konkurransedyktig plattform for deres verdiskapning.
        </div>
      </GridItem>
      <GridItem small={12} medium={6} mdxType="GridItem">
        <div className="merkevare__lofte">
          <div className="merkevare__lofte--header">For samfunnet</div>
          skal vi legge til rette for effektiv bruk av stadig økende mengder data
          i kollektivsektoren gjennom en åpen nasjonal digital plattform.
        </div>
      </GridItem>
    </GridContainer>
  </GridItem>
    </GridContainer>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      